<template>
  <div class="bk-main-canvas relative">
    <ClientOnly>
      <div
        v-if="drupalUser.accessToolbar && !isEditing"
        class="bg-white border-b border-b-gray-100 hidden lg:block"
      >
        <VuepalAdminToolbar :key="language" />
        <div class="flex">
          <div class="py-10 xl:min-w-[1174px] mx-auto w-auto">
            <VuepalLocalTasks />
          </div>
        </div>
      </div>
    </ClientOnly>

    <NuxtLayout :name="currentLayout">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const initData = await useInitData()

const currentLayout = computed(() => {
  if (initData.value?.siteInfo.domain !== 'sv') {
    return 'marketing'
  }
  return 'default'
})

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)
</script>
