import { defineNuxtPlugin } from '#app'
import { createGtm, type VueGtmUseOptions } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin({
  name: 'gtm-tracking',
  async setup({ $config, vueApp }) {
    const initData = await useInitData()
    const { gtm } = $config.public
    const IS_DEV = process.env.NODE_ENV === 'development'

    // Setup GTM ID per domain
    const gtmTagId =
      initData.value?.siteInfo.gtmTagId &&
      initData.value.siteInfo.gtmTagId !== ''
        ? initData.value.siteInfo.gtmTagId
        : gtm.id
    const options = {
      id: gtmTagId,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: IS_DEV,
      enableRouterSync: true,
      devtools: true,
      trackOnNextTick: false,
    }

    if (gtmTagId) {
      const router = useRouter()

      const pluginOptions: VueGtmUseOptions = {
        ...options,
        vueRouter:
          options.enableRouterSync && router
            ? (router as VueGtmUseOptions['vueRouter'])
            : undefined,
      }

      vueApp.use(createGtm(pluginOptions))
    }
  },
})
