import provideState_QdJe8OSSD_8wGCIAC_njNx2RCgNRiM6pPcjiRmvk3ic from "/builds/bazinga/sv-group/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/bazinga/sv-group/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/builds/bazinga/sv-group/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import language_eYKsUBVhhbAQxkgEQfGaQ9TEZ_6EZsjgtNqWUANErGs from "/builds/bazinga/sv-group/frontend/node_modules/nuxt-language-negotiation/dist/runtime/plugins/language.mjs";
import blokkliEditable_yH5y4jhW0pnh4qvyTY4vuxu7TVm4XVykmgjFQa3nF8Q from "/builds/bazinga/sv-group/frontend/node_modules/@blokkli/editor/dist/runtime/plugins/blokkliEditable.js";
import clickaway_ngS3d0J7NpiDHstVB3CpeVLXzJtj82x0rIRLxAZKwSc from "/builds/bazinga/sv-group/frontend/plugins/clickaway.ts";
import graphqlMiddleware_iWFzsP0MfTNrSebqRVAXpE_gZdmmQS8KQ8EW3l_Xcog from "/builds/bazinga/sv-group/frontend/plugins/graphqlMiddleware.ts";
import texts_lnAjn_dGKwYBMfpVPhpA1VKHaMBwEGKQeF7B1giuAoA from "/builds/bazinga/sv-group/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import drupalUser_csK0kmJtoe8MCRdEph29jVmq41JxUUPSrLlC7fbBQ0A from "/builds/bazinga/sv-group/frontend/plugins/drupalUser.ts";
import formkit_wpcyyBmakbDGXYolbhpQnV_KyvVJovVW039dbMjLh9s from "/builds/bazinga/sv-group/frontend/plugins/formkit.ts";
import rokkaDomain_bk3JHuwDBKr8L5Iz_gccN9H7Ibf7_o_j_WwvQIKrsHI from "/builds/bazinga/sv-group/frontend/plugins/rokkaDomain.ts";
import gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA from "/builds/bazinga/sv-group/frontend/plugins/gtm.client.ts";
import datadog_client_Eq8r59XvsX_h1ezY7kEMj1HkmHGU9JCWU6BlpxkH72w from "/builds/bazinga/sv-group/frontend/plugins/datadog.client.ts";
export default [
  provideState_QdJe8OSSD_8wGCIAC_njNx2RCgNRiM6pPcjiRmvk3ic,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  language_eYKsUBVhhbAQxkgEQfGaQ9TEZ_6EZsjgtNqWUANErGs,
  blokkliEditable_yH5y4jhW0pnh4qvyTY4vuxu7TVm4XVykmgjFQa3nF8Q,
  clickaway_ngS3d0J7NpiDHstVB3CpeVLXzJtj82x0rIRLxAZKwSc,
  graphqlMiddleware_iWFzsP0MfTNrSebqRVAXpE_gZdmmQS8KQ8EW3l_Xcog,
  texts_lnAjn_dGKwYBMfpVPhpA1VKHaMBwEGKQeF7B1giuAoA,
  drupalUser_csK0kmJtoe8MCRdEph29jVmq41JxUUPSrLlC7fbBQ0A,
  formkit_wpcyyBmakbDGXYolbhpQnV_KyvVJovVW039dbMjLh9s,
  rokkaDomain_bk3JHuwDBKr8L5Iz_gccN9H7Ibf7_o_j_WwvQIKrsHI,
  gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA,
  datadog_client_Eq8r59XvsX_h1ezY7kEMj1HkmHGU9JCWU6BlpxkH72w
]