import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: 'domain',
  async setup({ $config }) {
    const initData = await useInitData()
    const { rokkaHost, rokkaHostMarketing } = $config.public

    // Setup Rokka Host
    let newAppConfig = { rokkaHost: rokkaHost }

    if (initData.value?.siteInfo.domain !== 'sv') {
      newAppConfig = { rokkaHost: rokkaHostMarketing }
    }

    updateAppConfig(newAppConfig)
  },
})
