<template>
  <NuxtLayout name="error">
    <div
      class="py-8 md:py-16 text-center flex flex-col justify-center items-center min-h-screen"
      :class="[bgColor, textColor]"
    >
      <nuxt-link :to="{ name: 'home' }">
        <MediaImage v-if="logo" v-bind="logo" :image-style="logoStyle" />
        <SpriteSymbol v-else name="logo" class="h-12 md:h-16 mx-auto" />
      </nuxt-link>
      <div
        class="flex flex-col flex-1 justify-center items-center gap-10 px-16"
      >
        <h1 class="font-bold text-3xl md:text-4xl lg:text-6xl hyphens-auto">
          <span class="block">{{ title }}</span>
          <span v-if="titleLight" class="block font-light">{{
            titleLight
          }}</span>
        </h1>
        <div v-if="text" class="md:text-lg" v-html="text" />
        <NuxtLink :to="{ name: 'home' }" class="button" :class="buttonClass">
          {{ goToHome }}
        </NuxtLink>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
interface ErrorMessages {
  accessDeniedTitle: string
  accessDeniedText: string
  pageNotFoundTitle: string
  pageNotFoundText: string
  serverErrorTitle: string
  goToHome: string
  errorText: string
}

const ERRORS: Record<string, ErrorMessages> = {
  de: {
    accessDeniedTitle: 'Zugriff verweigert',
    accessDeniedText: 'Sie haben keinen Zugriff auf diese Seite.',
    pageNotFoundTitle: 'Seite nicht gefunden',
    pageNotFoundText:
      'Die von Ihnen angeforderte Seite konnte nicht gefunden werden.',
    serverErrorTitle: 'Seite nicht verfügbar',
    goToHome: 'Zur Startseite',
    errorText: 'Fehler',
  },
  fr: {
    accessDeniedTitle: 'Accès refusé',
    accessDeniedText: "Vous n'avez pas accès à cette page.",
    pageNotFoundTitle: 'Page introuvable',
    pageNotFoundText: "La page que vous avez demandée n'a pas pu être trouvée.",
    serverErrorTitle: 'Page non disponible',
    goToHome: 'Aller à la page d’accueil',
    errorText: 'Erreur',
  },
  en: {
    accessDeniedTitle: 'Access denied',
    accessDeniedText: 'You do not have access to this page.',
    pageNotFoundTitle: 'Page not found',
    pageNotFoundText: 'The page you requested was not found.',
    serverErrorTitle: 'Page unavailable',
    goToHome: 'Go to homepage',
    errorText: 'Error',
  },
}

type ErrorObject = {
  url?: string
  statusCode?: number
  statusMessage?: string
  message?: string
  stack?: string
}

const currentLanguage = useCurrentLanguage()
const language = computed<string>(() => {
  const v = currentLanguage.value
  if (v && typeof v === 'string') {
    return v
  }

  return 'de'
})

const initData = await useInitData()

const texts = computed<ErrorMessages>(() => {
  if (language.value && ERRORS[language.value]) {
    return ERRORS[language.value]
  }
  return ERRORS.de
})

const props = defineProps<{
  error: ErrorObject
}>()

const title = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedTitle
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundTitle
  }

  if (props.error?.statusMessage) {
    return props.error.statusMessage
  }

  return texts.value.serverErrorTitle
})

const titleLight = computed(() => {
  if (props.error?.statusCode) {
    return `${texts.value.errorText} ${props.error.statusCode}`
  }
  return texts.value.errorText
})

const text = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedText
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundText
  }

  return props.error?.message
})

const logo = initData.value.siteInfo.logo

const logoStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 215,
    },
  },
})

const bgColor = computed(() => {
  if (initData.value?.siteInfo.domain !== 'sv') {
    return 'bg-header-footer-background'
  }
  return 'bg-primary-500'
})

const textColor = computed(() => {
  if (initData.value?.siteInfo.domain !== 'sv') {
    return 'text-header-footer-text'
  }
  return 'text-white'
})

const buttonClass = computed(() => {
  if (initData.value?.siteInfo.domain !== 'sv') {
    return 'is-filled'
  }
  return 'is-inverted'
})
const goToHome = computed(() => {
  return texts.value.goToHome
})

onMounted(() => {
  clearError()
})
</script>
