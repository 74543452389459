<template>
  <RokkaImage
    :alt="alt"
    :title="title"
    :hash="file?.rokka?.hash ?? ''"
    :config="imageStyle"
    :loading="loading"
    :preload="preload"
    :source-width="file?.rokka?.sourceWidth"
    :source-height="file?.rokka?.sourceHeight"
    :class="imgClass"
    :host="appConfig.rokkaHost"
    :file-name="file?.label"
  />
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { ImageItemFragment } from '#graphql-operations'
import { RokkaImage } from '#components'

defineOptions({
  name: 'ImageItem',
})

withDefaults(
  defineProps<{
    file?: ImageItemFragment['file']
    alt?: string
    title?: string
    imageStyle?: DefineImageStyleConfig | string
    imgClass?: string
    preload?: boolean
    loading?: 'lazy' | 'eager'
  }>(),
  {
    file: undefined,
    alt: '',
    title: '',
    imageStyle: () => {
      return 'wide'
    },
    imgClass: '',
    preload: false,
    loading: 'lazy',
  },
)

const appConfig = useAppConfig()
</script>
