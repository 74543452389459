
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexeH3_45BiGCkZfTqSfNrfYZEYgufE0qB9x3XHmxeQmrCUIMeta } from "/builds/bazinga/sv-group/frontend/pages/index.vue?macro=true";
import { default as _91id_93BOgF18lCO3zTmCDkEaxm50RSgZviBYYfeCqsdOmSNVEMeta } from "/builds/bazinga/sv-group/frontend/pages/form/[id].vue?macro=true";
import { default as index5BAIGYJd6WYY_45V33VNrl10dDnB_45NvU3_W_45x91aQvMcIMeta } from "/builds/bazinga/sv-group/frontend/pages/search/index.vue?macro=true";
import { default as indexAnsyzIaXr_xnactPsJUk61vVRT6FQXp9u7VpUOajDYwMeta } from "/builds/bazinga/sv-group/frontend/pages/[...slug]/index.vue?macro=true";
import { default as chGQGWaGh2WvuuCjIBqG8_450qkyzZ04CAeQJfIfHWbItxEMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/ch.vue?macro=true";
import { default as deUtG89u4ovmHlOaRQZ3fHjGm46_45ZdGDXOmD_7rMODZo8Meta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/de.vue?macro=true";
import { default as indexmVQvXzhzTBp_79_45kY6dqxx4M50IsH0oFwsF3oLUX2oEMeta } from "/builds/bazinga/sv-group/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as groupe9JcHhGfFuhAxutCDuQmFJXCV9k8eCxH_vrsJdy9BjQMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/group.vue?macro=true";
import { default as hotel5PbTDbNqwR4yNtX8cOFyq9E0hDca_GwRQkvKkGfpVjsMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/hotel.vue?macro=true";
import { default as _91id_930XkKUjQAWvWDmYCA84p81jrSV_45oJHpEa_XbOt5woQy4Meta } from "/builds/bazinga/sv-group/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexsBB3qCSJsx3uA62Kg6tr_P3rHXlqS_Tl1KUsVAYoxFoMeta } from "/builds/bazinga/sv-group/frontend/pages/press-release/[...slug]/index.vue?macro=true";
export default [
  {
    name: "home",
    path: "/",
    meta: indexeH3_45BiGCkZfTqSfNrfYZEYgufE0qB9x3XHmxeQmrCUIMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/index.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_93BOgF18lCO3zTmCDkEaxm50RSgZviBYYfeCqsdOmSNVEMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/form/[id].vue")
  },
  {
    name: "search",
    path: "/search",
    meta: index5BAIGYJd6WYY_45V33VNrl10dDnB_45NvU3_W_45x91aQvMcIMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/search/index.vue")
  },
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexAnsyzIaXr_xnactPsJUk61vVRT6FQXp9u7VpUOajDYwMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "landing-page-ch",
    path: "/landing-page/ch",
    meta: chGQGWaGh2WvuuCjIBqG8_450qkyzZ04CAeQJfIfHWbItxEMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/ch.vue")
  },
  {
    name: "landing-page-de",
    path: "/landing-page/de",
    meta: deUtG89u4ovmHlOaRQZ3fHjGm46_45ZdGDXOmD_7rMODZo8Meta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/de.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexmVQvXzhzTBp_79_45kY6dqxx4M50IsH0oFwsF3oLUX2oEMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "landing-page-group",
    path: "/landing-page/group",
    meta: groupe9JcHhGfFuhAxutCDuQmFJXCV9k8eCxH_vrsJdy9BjQMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/group.vue")
  },
  {
    name: "landing-page-hotel",
    path: "/landing-page/hotel",
    meta: hotel5PbTDbNqwR4yNtX8cOFyq9E0hDca_GwRQkvKkGfpVjsMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/hotel.vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_930XkKUjQAWvWDmYCA84p81jrSV_45oJHpEa_XbOt5woQy4Meta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "press-release-detail",
    path: "/press-release/:slug(.*)*",
    meta: indexsBB3qCSJsx3uA62Kg6tr_P3rHXlqS_Tl1KUsVAYoxFoMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/press-release/[...slug]/index.vue")
  }
]